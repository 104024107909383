




























































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { IThemes, ITextColor, IBgColor } from '@/models/Setting/Interface'
import { EhumbNailsDisplay, EThemeType } from '@/models/Setting/Enum'
import SettingService from '@/services/SettingService'
import store from '@/store'
import Setting from '@/store/modules/Setting'
//@ts-ignore
import _ from 'lodash'
const SettingModule = getModule(Setting, store)

@Component
export default class SettingTheme extends Vue {
  private erorrTypeAndSize: boolean = false
  private errorDimension: boolean = false
  private eThemeType: any = EThemeType
  private theme_type: EThemeType = EThemeType.NO_THEME
  private confirmChange: any
  private upload_header: any = ''
  private upload_header_name: any = ''
  private selected_bgColor: string = ''
  private selected_textColor: string = ''
  private selected_theme_id: string = ''
  private selected_theme: any = {}
  private list_title_colors: ITextColor[] = []
  private list_title_bgColors: IBgColor[] = []
  private list_themes: IThemes[] = []
  private ehumbNailsDisplay = EhumbNailsDisplay

  created() {
    this.getListOfThemes()
    this.getTitleBgColor()
    this.getTitleColor()
    this.getCurrentTheme()
    this.upload_header = null
    this.upload_header_name = null
    if (this.theme_type === EThemeType.CUSTOM_THEME) {
      this.getCurrentTitleBg()
      this.getCurrentTitleColor()
      this.getCurrentHeader()
    }
  }

  checkRouterBeforLeave() {
    return (
      _.isEqual(
        this.theme_type,
        store.state.setting.setting.setting_themes.theme_type
      ) &&
      _.isEqual(
        this.selected_theme_id,
        store.state.setting.setting.setting_themes.theme.id
      ) &&
      (this.theme_type === EThemeType.CUSTOM_THEME
        ? _.isEqual(
            this.upload_header,
            store.state.setting.setting.setting_themes.theme
              .banner_header_bg_img
          ) &&
          _.isEqual(
            this.selected_bgColor,
            store.state.setting.setting.setting_themes.theme.title_background
          ) &&
          _.isEqual(
            this.selected_textColor,
            store.state.setting.setting.setting_themes.theme.title_color
          )
        : true)
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.checkRouterBeforLeave()) {
      this.confirmChange = next
      this.$bvModal.show('modal-confirm')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  getListOfThemes() {
    SettingService.getThemes()
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.length > 30) {
            this.list_themes = res.data.slice(0, 30)
          } else {
            this.list_themes = res.data
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getCurrentTheme() {
    this.selected_theme_id = store.state.setting.setting.setting_themes.theme.id
    this.selected_theme = store.state.setting.setting.setting_themes.theme
    this.theme_type = store.state.setting.setting.setting_themes.theme_type
  }

  getTitleBgColor() {
    SettingService.getTitleBgColor()
      .then((res: any) => {
        this.list_title_bgColors = res.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  getTitleColor() {
    SettingService.getTitleColor()
      .then((res: any) => {
        this.list_title_colors = res.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  getCurrentHeader() {
    this.upload_header =
      store.state.setting.setting.setting_themes.theme.banner_header_bg_img
    this.upload_header_name =
      store.state.setting.setting.setting_themes.header_file_name
  }

  getCurrentTitleBg() {
    this.selected_bgColor =
      store.state.setting.setting.setting_themes.theme.title_background
  }

  getCurrentTitleColor() {
    this.selected_textColor =
      store.state.setting.setting.setting_themes.theme.title_color
  }

  onFileInputChange(event: any) {
    const _25mb = 26214400
    let files = event.target.files || event.dataTransfer.files
    let imageType = ['image/png', 'image/gif', 'image/jpeg']
    if (!files.length) return
    if (!imageType.includes(files[0].type) || files[0].size > _25mb) {
      this.erorrTypeAndSize = true
      return
    }
    this.onThemeChange(files[0])
  }

  onThemeChange(file: any) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e: any) => {
      let image = new Image()
      image.onload = () => {
        if (image.width < 500 || image.height < 100) {
          this.errorDimension = true
        } else {
          this.upload_header = this.resizeWidthHeight(image)
          this.upload_header_name = file.name
        }
      }
      image.src = e.target.result
    }
  }

  resizeWidthHeight(image: any) {
    const MAX_WH = 1500
    if (image.width > MAX_WH || image.height > MAX_WH) {
      const canvas = document.createElement('canvas')
      const ctx: any = canvas.getContext('2d')
      let width = image.width
      let height = image.height
      if (width > height) {
        width = MAX_WH
        height = height * (MAX_WH / image.width)
      } else if (width < height) {
        height = MAX_WH
        width = width * (MAX_WH / image.height)
      } else {
        width = MAX_WH
        height = MAX_WH
      }
      canvas.width = width
      canvas.height = height
      ctx.drawImage(image, 0, 0, width, height)
      return canvas.toDataURL('image/jpeg')
    }
    return image.src
  }

  resetMsg() {
    this.erorrTypeAndSize = false
    this.errorDimension = false
  }

  async handleChangeTheme() {
    this.errorDimension = false
    this.erorrTypeAndSize = false
    let setting_data = { ...store.state.setting.setting }
    setting_data.setting_themes.theme = { ...store.state.setting.theme_default }
    if (this.theme_type === EThemeType.NO_THEME) {
      setting_data.setting_themes.theme_type = this.theme_type
      setting_data.setting_themes.header_file_name = null
    }
    if (this.theme_type === EThemeType.CUSTOM_THEME) {
      setting_data.setting_themes.theme_type = this.theme_type
      setting_data.setting_themes.header_file_name = this.upload_header_name
      setting_data.setting_themes.theme.banner_header_bg_img = this.upload_header
      setting_data.setting_themes.theme.title_background = this.selected_bgColor
      setting_data.setting_themes.theme.title_color = this.selected_textColor
    }
    if (this.theme_type === EThemeType.PRESCRIPTIVE_THEME) {
      this.selected_theme = this.list_themes.find(
        (item: any) => item.id === this.selected_theme_id
      )
      setting_data.setting_themes.header_file_name = null
      setting_data.setting_themes.theme = this.selected_theme
      setting_data.setting_themes.theme_type = this.theme_type
    }
    this.$blockui.show()
    await SettingService.updateUserSetting(setting_data)
      .then(response => {
        if (response.status === 200) {
          SettingModule.CHANGE_THEME_TYPE(
            response.data.setting_themes.theme_type
          )
          SettingModule.CHANGE_THEME(response.data.setting_themes.theme)
          SettingModule.CHANGE_TITLE_BACKRGROUND(
            response.data.setting_themes.theme.title_background
          )
          SettingModule.CHANGE_TITLE_COLOR(
            response.data.setting_themes.theme.title_color
          )
          SettingModule.CHANGE_HEADER(
            response.data.setting_themes.theme.banner_header_bg_img
          )
          SettingModule.CHANGE_HEADER_FILE_NAME(
            response.data.setting_themes.header_file_name
          )
          this.$bvModal.show('modal-success')
        }
      })
      .catch(error => {
        this.$bvModal.show('modal-error')
      })
    this.$blockui.hide()
    this.getCurrentTheme()
    if (this.theme_type === EThemeType.CUSTOM_THEME) {
      this.getCurrentTitleBg()
      this.getCurrentTitleColor()
      this.getCurrentHeader()
    } else {
      this.selected_textColor = ''
      this.selected_bgColor = ''
      this.upload_header = null
      this.upload_header_name = null
    }
  }
}
